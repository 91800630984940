<template>
  <!-- 参考 https://blog.csdn.net/yangyangdt/article/details/122431865 -->
  <div class="wrapper">
    <!-- 343 150 -->
    <!--1. 轮播默认显示3个,可以改成显示一个滑块  :slides-per-view="1"-->
    <!-- 5.自动轮播  加入:autoplay -->
    <swiper
      :modules="modules"
      :slides-per-view="1"
      :space-between="0"
      :pagination="{ clickable: true }"
      :autoplay="{
			      delay: 3000,
			      disableOnInteraction: false,
			    }"
      :loop="true"
      @swiper="onSwiper"
      @slideChange="onSlideChange"
    >
      <!-- 4.swiper要v-for 遍历   图片改成自己的-->
      <swiper-slide v-for="(item,index) in carouselList" :key="index">
        <a v-if="item.link" :href="item.link" target="_blank">
          <img :src="item.image" />
        </a>
        <span v-else>
          <img :src="item.image" />
        </span>
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
  </div>
</template>
<script>
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper'

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue.js'
import 'swiper/swiper.min.css'
import 'swiper/modules/navigation/navigation.min.css'
import 'swiper/modules/pagination/pagination.min.css'
import 'swiper/modules/scrollbar/scrollbar.min.css'

export default {
  name: 'carrousel',
  props: {
    // 传入渲染数据
    carouselList: {
      type: Object,
      default: () => {
        return []
      },
    },
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  mounted() {},
  methods: {
    onSwiper(swiper) {
      console.log(swiper)
    },
    onSlideChange() {
      // console.log('slide change')
    },
  },
  data() {
    return {
      modules: [Pagination, Autoplay],
    }
  },
}
</script>
<style scoped>
/deep/.swiper-pagination-bullet {
}
/deep/.swiper-pagination-bullet-active {
  background: #ff5500;
  transition: width 0.2s linear;
}
/deep/.swiper-container-horizontal > .swiper-pagination-bullets {
  width: auto;
  left: inherit;
  right: 20px;
}
img {
  width: 1400px;
  height: 347px;
}
</style>
